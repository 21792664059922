<template>

    <v-dialog v-model="$axios.modal.show" width="350" class="ma-0 pa-0" persistent>
        <v-card class="ma-0 pa-0">
        <v-icon v-if="$axios.modal.showclose" @click="$axios.modal.show = false" style="position: absolute; right: 0px; margin: 5px">mdi-close</v-icon>
            <v-card-text class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="px-4 py-10" style="text-align: center">
                        <h2 v-html="$axios.modal.titulo" :style="'color: ' + $config.dados.corprimaria"></h2>
                        <div v-html="$axios.modal.conteudo" class="my-6"></div>
                        <v-btn dark v-if="$axios.modal.botao && $axios.modal.botao != ''" :color="$config.dados.corprimaria" @click="$axios.modal.link ? open($axios.modal.link) : $axios.modal.show = false">{{ $axios.modal.botao }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>

</template>

<script>
    import { ENDPOINTS } from '@/plugins/service/Endpoints.js'
    export default {
        name: 'Modal',
        data() {
            return {
                ENDPOINTS: ENDPOINTS,
                dados: [],
                show: false,
                conteudo: []
            }
        },

        watch: {
            '$axios.modal': {
                handler(novo){
                    if(novo.show == true){
                        this.$carrinho.atualizaCarrinho(false)
                    }
                },
                deep: true 
            }
        },
        methods: {
            open(link){
                if(link){ location.href = link }
                else { this.$axios.modal.show }
            }
        }
    }
</script>

<style scoped>
    .ca-popups {
        min-height: 110px;
    }
</style>